import Link from 'next/link';
import styles from '../styles/Home.module.css';

const TrepHeader = () => {
  return (
    <>
      <Link href="/">
        <a>
          <div style={{ paddingTop: '10px' }}>
            <span style={{ color: '#5F9EA0' }}>team</span>
            <span style={{ color: '#6B8E23' }}>reports</span>
          </div>
          <h1 className={styles.title}>
            <span style={{ color: '#5F9EA0' }}>team</span>
            <span style={{ color: '#6B8E23' }}>reports</span>
            <span style={{ color: '#5F9EA0' }}>.i</span>
            <span style={{ color: '#6B8E23' }}>o</span>
          </h1>
        </a>
      </Link>
    </>
  );
};

export default TrepHeader;
