import Head from 'next/head';
import styles from '../styles/Home.module.css';
import { readSSMParameter } from '../lib/init';
import { PROD_SPECS } from '../lib/prod-specs';
import { useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import PricingCard from '../comps/PricingCard';
// import { Template } from '@styled-icons/heroicons-outline';
import { InsertTemplate } from '@styled-icons/icomoon';
import { PeopleTeam } from '@styled-icons/fluentui-system-filled';
import { Elastic } from '@styled-icons/simple-icons';
import { Workflow } from '@styled-icons/octicons';
import { Security } from '@styled-icons/material-rounded';
import { EmojiNature } from '@styled-icons/material';
import TrepHeader from '../comps/TrepHeader';
import Image from 'next/image';
import YouTube from 'react-youtube';
import SimpleMenu from '../comps/SimpleMenu';
import { useRouter } from 'next/router';

export async function getStaticProps() {
  try {
    let isSubscriptionEnabled = true;
    console.log('executing getServerSideProps');
    isSubscriptionEnabled = await readSSMParameter(
      `/trep/${process.env.Environment}/subscription/enabled`
    );
    const onboardLiteApiurl = await readSSMParameter(
      `/trep/${process.env.Environment}/onboardliteapiurl`
    );
    const onboardVetApiurl = await readSSMParameter(
      `/trep/${process.env.Environment}/onboardapiurl`
    );

    console.log(`subscription enabled:${isSubscriptionEnabled}`);
    return {
      props: {
        isSubscriptionEnabled: isSubscriptionEnabled === 'true' ? true : false,
        onboardLiteApiurl,
        onboardVetApiurl,
      },
    };
  } catch (err) {
    console.log(err);
  }
}

export default function Home({
  isSubscriptionEnabled,
  onboardLiteApiurl,
  onboardVetApiurl,
  appContext,
  setAppContext,
}) {
  const router = useRouter();
  const [showDangerAlert, setShowDangerAlert] = useState(false);

  const subscribeRequest = async (
    tier,
    onboardLiteApiurl,
    onboardVetApiurl
  ) => {
    const subDomainName = tier == 'lite' ? tier : '';
    setAppContext({
      ...appContext,
      subDomainName,
      tier,
      onboardLiteApiurl,
      onboardVetApiurl,
    });
    router.push('/onboard');
  };

  const opts = {
    height: '300',
    width: '300',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  function _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  return (
    <div className={styles.container}>
      <Head>
        <title>teamreports.io</title>
        <meta name="description" content="Build Transparency" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <TrepHeader />
      <br />
      <main className={styles.main}>
        <SimpleMenu />
        <Alert
          variant="danger"
          show={showDangerAlert}
          onClose={() => setShowDangerAlert(false)}
          dismissible
        >
          <Alert.Heading>Something went wrong!</Alert.Heading>
          <p>Please contact support@teamreports.io</p>
        </Alert>

        <p className={styles.description} style={{ color: '#375361' }}>
          Automate and streamline the process of collecting information from the
          team and sharing with the Stakeholders
        </p>

        <div>
          <Row>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '10px',
              }}
            >
              <h2>Stakeholders Reporting Process</h2>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <h3 style={{ display: 'flex', justifyContent: 'center' }}>
                Conventional way
              </h3>
              <br />
              <Image
                src="/info-trep-before.webp"
                priority
                width={650}
                height={500}
              />
            </Col>
            <Col>
              <h3 style={{ display: 'flex', justifyContent: 'center' }}>
                With teamreports
              </h3>
              <br />
              <Image
                src="/info-trep-after.webp"
                priority
                width={650}
                height={500}
              />
            </Col>
          </Row>
        </div>
        <p
          className={styles.description}
          style={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '1000px',
          }}
        >
          <span style={{ color: '#5F9EA0' }}>team</span>
          <span style={{ color: '#6B8E23' }}>reports</span>
          <span style={{ color: '#5F9EA0' }}>.i</span>
          <span style={{ color: '#6B8E23' }}>o</span>
          <span>
            empowers teams in building reports collaboratively eliminating the
            manual efforts of collating the information and emailing
            stakeholders{' '}
          </span>
        </p>
        <a id="features">
          <p className={styles.subtitle}>Features</p>
        </a>

        <div className={styles.grid}>
          <a className={styles.card}>
            <h2>
              <InsertTemplate size="35" title="Author" /> Templates
            </h2>
            <p>Configurable Report Templates supporting variety of use cases</p>
          </a>

          <a className={styles.card}>
            <h2>
              <PeopleTeam size="35" title="collaborate" /> Collaboration
            </h2>
            <p>
              Collaborative Report Generation with well-defined responsibilities
            </p>
          </a>

          <a className={styles.card}>
            <h2>
              <Elastic size="35" title="Flexibility" /> Flexible
            </h2>
            <p>Adhoc, Recurring Reports, Performance Appraisal and many more</p>
          </a>

          <a className={styles.card}>
            <h2>
              <Workflow size="35" title="Workflow" /> Workflow
            </h2>
            <p>
              Customizable Workflow driven Reporting process with feedback loop
            </p>
          </a>

          <a className={styles.card}>
            <h2>
              <Security size="35" title="Group" /> Security
            </h2>
            <p>Role based and Report specific access permissions</p>
          </a>

          <a className={styles.card}>
            <h2>
              <EmojiNature size="35" title="Sustainability" /> Sustainable
            </h2>
            <p>Low carbon footprint. 100% Serverless with 80% Functionless</p>
          </a>
        </div>

        <a id="pricing">
          <p className={styles.subtitle}>Pricing</p>
        </a>

        <div className={styles.grid} style={{ maxWidth: '1400px' }}>
          {PROD_SPECS.map((prod, index) => (
            <PricingCard
              key={`prod-${index}`}
              {...prod}
              isSubscriptionEnabled={!prod?.disabled && (isSubscriptionEnabled || router.query?.demo)}
              buttonClicked={() =>
                subscribeRequest(prod.tier, onboardLiteApiurl, onboardVetApiurl)
              }
            />
          ))}
        </div>
      </main>

      <footer className={styles.footer}>reachteamreports@gmail.com</footer>
    </div>
  );
}
