import styles from '../styles/Home.module.css';
import Button from 'react-bootstrap/Button';

const PricingCard = ({
  isSubscriptionEnabled,
  title,
  subtitle,
  features,
  buttonText,
  borderHighlight,
  buttonClicked,
  tier,
}) => {
  return (
    <div
      className={styles.pricingcard}
      style={borderHighlight ? { backgroundColor: '#DFE9EB' } : undefined}
      key="key-{title}"
    >
      <h2>{title} &rarr;</h2>
      <h3>{subtitle}</h3>
      <div className={styles.line}></div>
      <div style={{ paddingTop: '10px', height: '220px' }}>
        <ul>
          {features.map((item) => (
            <li key={`key-${item}`}>{item}</li>
          ))}
        </ul>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '10px',
        }}
      >
        {isSubscriptionEnabled && (
          <Button
            variant="outline-secondary"
            onClick={() => buttonClicked(tier)}
          >
            {buttonText}
          </Button>
        )}
        {!isSubscriptionEnabled && (
          <Button variant="outline-secondary" disabled>
            Coming Soon
          </Button>
        )}
      </div>
    </div>
  );
};

export default PricingCard;
