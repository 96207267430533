import styles from '../styles/Home.module.css';
import Link from 'next/link';

const SimpleMenu = ({}) => {
  return (
    <nav>
      <Link href={`#features`}>
        <a className={styles.menu}>Features</a>
      </Link>
      <Link href={`#pricing`}>
        <a className={styles.menu}>Pricing</a>
      </Link>
      <Link href={`#videos`}>
        <a className={styles.menu}>Videos</a>
      </Link>
      <a
        target="_blank"
        href="https://github.com/vgthoppae/teamreports.io.docs"
        rel="noopener noreferrer"
        className={styles.menu}
      >
        Docs
      </a>
    </nav>
  );
};

export default SimpleMenu;
